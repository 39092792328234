<template>
  <b-card>

    <!-- danger -->
    <template v-if="asset?.status == 'Assigned'">
      <b-alert variant="warning" show>
        <h4 class="alert-heading">Asset Is Already Assigned!</h4>
        <div class="alert-body">
          <span
            >This asset is already assigned to
            <b>
              Company Serial ID :
              {{ assignedToAssetInfo?.company_serial_number }}, Name:
              {{ assignedToAssetInfo?.title }}</b
            >.</span
          >
          <br />
          <span>
            Relase this asset first to assign it under another asset
          </span>
        </div>
      </b-alert>
    </template>

    <template v-else-if="asset?.status === 'Available'">
      <b-alert variant="success" show>
        <h4 class="alert-heading">Asset Is Available!</h4>
        <div class="alert-body">
          <span>You can assign it under another asset </span><br />
          <template v-if="assignedToAssetInfo">
            <span
              >This asset was last assigned to
              <b>
                Company Serial ID :
                {{ assignedToAssetInfo?.company_serial_number }}, Name:
                {{ assignedToAssetInfo?.title }}</b
              >.</span
            >
          </template>
        </div>
      </b-alert></template
    >
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template v-if="asset?.status == 'Assigned'">
            <template
              v-if="
                $permissionAbility(ASSET_REPAIR_HISTORY_CREATE, permissions)
              "
            >
              <b-button
                class="flex-shrink-0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                v-on:click="releaseAsset"
              >
                Release
              </b-button>
            </template>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :rows="rows"
        :columns="columns"
        styleClass="vgt-table table-custom-style striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :line-numbers="false"
        :totalRows="totalRecords"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'assigned_date', type: 'desc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <template v-if="props.column.field === 'format_assigned_to'">
            <div>
              <!-- <span>{{ props?.row?.assigned_to?.title }}</span
              ><br />
              <span>
                ID: {{ props?.row?.assigned_to?.company_serial_number }}
              </span> -->

              <div>
                <b-link
                  v-on:click="onShowAssetDetails(props?.row?.assigned_to?.id)"
                >
                  {{ props?.row?.assigned_to?.title }}
                </b-link>

                <br />
                <span>
                  ID: {{ props?.row?.assigned_to?.company_serial_number }}
                </span>
              </div>
            </div>
          </template>
          <!-- remarks -->
          <div
            v-if="props.column.field === 'format_purpose'"
            class="text-center"
          >
            <b-button
              class="flex-shrink-0 btn-sm text-center"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.top="props?.row?.purpose"
              >{{ props?.row?.purpose.slice(0, 5) }} ...</b-button
            >
          </div>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <template
                  v-if="
                    $permissionAbility(ASSET_REPAIR_HISTORY_DELETE, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </b-card>
</template>

      <script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BImg,
  BLink,
  BFormDatepicker,
  BFormTextarea,
  VBTooltip,
  BAlert,
  BRow,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { DownloadCloudIcon } from "vue-feather-icons";

import {
  ASSET_REPAIR_HISTORY_ACCESS,
  ASSET_REPAIR_HISTORY_CREATE,
  ASSET_REPAIR_HISTORY_EDIT,
  ASSET_REPAIR_HISTORY_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "AdditionalAssetHistory",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BModal,
    BSpinner,
    BImg,
    BLink,
    DownloadCloudIcon,
    BFormTextarea,
    BAlert,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      ASSET_REPAIR_HISTORY_ACCESS,
      ASSET_REPAIR_HISTORY_CREATE,
      ASSET_REPAIR_HISTORY_EDIT,
      ASSET_REPAIR_HISTORY_DELETE,

      assetIdOption: [],
      assginedAssetId: "",
      additionalAssetId: "",
      assignedDate: "",
      releaseDate: "",
      assignedToId: "",

      additionalAssetAssignHistoryId: "",
      modelType: "",
      asset: "",
      assignedToAssetInfo: "",
      pageLength: 10,
      columns: [
        {
          label: "Assigned To",
          field: "format_assigned_to",
          sortable: false,
        },
        {
          label: "Assigned By",
          field: "created_by.name",
          sortable: false,
        },

        {
          label: "Assigned Date",
          field: "assigned_date",
          formatFn: this.formatFnTableDate,
          sortable: false,
        },
        {
          label: "Release Date",
          field: "release_date",
          formatFn: this.formatFnTableDate,
          sortable: false,
        },
        // {
        //   label: "Created",
        //   field: "created_at",
        //   sortable: false,
        // },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      totalRecords: 0,
      searchTerm: "",
      serverParams: {
        columnFilters: {},
        sort: [{ field: "assigned_date", type: "desc" }],
        page: 1,
        perPage: 10,
      },
      isAdditionalAssetAssignFormSubmitLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      this.getAssetInfo();
      // this.loadItems();
      const additionalAssets = await this.$api.get(`/api/assets/additional`);

      this.assetIdOption = (additionalAssets?.data?.data || []).map((item) => {
        let name = item.title;

        return {
          name,
          id: item.id,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    onShowAssetDetails(id) {
      const routeURL = this.$router.resolve({
        name: "admin-assets-details",
        params: { id },
      }).href;

      window.open(routeURL, "_blank");
    },

    formatFnTableDate(value) {
      if (value) {
        return this.$moment(value).format("MMMM DD, YYYY");
      }
      return "Not Set";
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    async getAssetInfo() {
      this.assetId = this.$route.params.id;
      const asset = await this.$api.get(`/api/assets/${this.assetId}`);
      this.asset = asset?.data?.data;
    },
    showModal() {
      this.$bvModal.show("modal-asset-assign-asset-form");
    },

    hiddenModal() {
      this.$bvModal.hide("modal-asset-assign-asset-form");
      this.resetModal();
    },

    resetModal() {
      this.modelType = "";
      this.additionalAssetId = "";
      this.assginedAssetId = "";
      this.assignedDate = "";
      this.releaseDate = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.additionalAssetId = value?.assigned_by?.id;
      this.assginedAssetId = value?.assigned_to?.id;
      this.assignedDate = value?.assigned_date;
      this.releaseDate = value?.release_date;
      this.additionalAssetAssignHistoryId = value?.id;
      this.showModal();
    },

    async releaseAsset() {
      this.assetId = this.$route.params.id;
      this.assignedToId = this.assignedToAssetInfo?.id;

      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Release This Asset?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.post(
              `/api/additional-asset-released/${this.assetId}/${this.assignedToId}`
            );

            this.loadItems();
            this.getAssetInfo();
            this.$emit("loadAssetInfo");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Asset Released Successfully",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/additional-assets/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "History Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getAdditionalAssetAssignHistory(params) {
      this.assetId = this.$route.params.id;
      return await this.$api.get(`api/additional-assets-assign-histories`, {
        params: {
          additional_asset_id: this.assetId,
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    async loadItems() {
      try {
        const assetInfo = await this.getAdditionalAssetAssignHistory({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = assetInfo?.data?.data;
        const meta = assetInfo?.data?.meta;
        this.rows = data;
        this.totalRecords = meta?.pagination?.total;
        if (
          this.serverParams.sort[0].type == "desc" &&
          this.serverParams.sort[0].field == "assigned_date"
        ) {
          this.assignedToAssetInfo = data[0]?.assigned_to;
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.assetAssignUserValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isAdditionalAssetAssignFormSubmitLoading = true;

              await this.$api.put(
                `/api/additional-assets/${this.additionalAssetAssignHistoryId}`,
                {
                  assigned_to: this.assginedAssetId,
                  assigned_by: this.additionalAssetId,
                  assigned_date: this.assignedDate,
                  release_date: this.releaseDate,
                }
              );
              this.isAdditionalAssetAssignFormSubmitLoading = false;
              this.loadItems();

              this.hiddenModal();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  load,
                  icon: "BellIcon",
                  variant: "success",
                  text: "History Successfully Updated",
                },
              });
            } else {
              this.isAdditionalAssetAssignFormSubmitLoading = true;

              this.assginedAssetId = this.$route.params.id;

              await this.$api.post("/api/additional-assets", {
                assigned_by: this.additionalAssetId,
                assigned_to: this.assginedAssetId,
                assigned_date: this.assignedDate,
                release_date: this.releaseDate,
              });
              this.isAdditionalAssetAssignFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Asset Assigned To User",
                },
              });
              this.getAssetInfo();
              this.$emit("loadAssetInfo");
            }
          } catch (error) {
            this.isAdditionalAssetAssignFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.assetAssignUserValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

      <style lang="scss">
.asset-assign-history-table {
  min-height: 137px !important;
  white-space: nowrap !important;
  font-size: 13px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.remarks-para p {
  margin-bottom: 0rem !important;
  font-size: 13px !important;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>


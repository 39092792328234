var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4, _vm$data5, _vm$data6, _vm$data7, _vm$data8, _vm$data9, _vm$data10, _vm$data11, _vm$data12, _vm$data13, _vm$data14, _vm$data15, _vm$data16, _vm$data17, _vm$data18, _vm$data19, _vm$data20, _vm$data21, _vm$data22, _vm$data23, _vm$data24, _vm$data25, _vm$data26, _vm$data27, _vm$data28;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "title": "Asset Information"
    }
  }, [_c('b-img', {
    staticClass: "custom-asset-image mb-2",
    attrs: {
      "center": "",
      "src": (_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.asset_image,
      "alt": "assetImage"
    }
  }), _c('b-card-text', {
    staticClass: "text-center"
  }, [_c('h3', [_vm._v(_vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.title))]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" User Assign Count: "), (_vm$data3 = _vm.data) !== null && _vm$data3 !== void 0 && _vm$data3.total_assigned ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.total_assigned))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Repair Count: "), (_vm$data5 = _vm.data) !== null && _vm$data5 !== void 0 && _vm$data5.total_repaired ? _c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : _vm$data6.total_repaired))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)])], 1)], 1), _c('hr'), _c('b-card-text', [_c('p', {
    staticClass: "text-uppercase custom-text-disabled"
  }, [_vm._v("Details")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('h6', {
    staticClass: "text-base"
  }, [_vm._v(" Title: "), _c('span', {
    staticClass: "text-body-2 font-weight-semibold"
  }, [_vm._v(_vm._s((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : _vm$data7.title))])])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base"
  }, [_vm._v(" Serial ID: "), (_vm$data8 = _vm.data) !== null && _vm$data8 !== void 0 && _vm$data8.company_serial_number ? _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(_vm._s((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : _vm$data9.company_serial_number))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base "
  }, [_vm._v(" Manufacturer ID: "), _c('span', {
    staticClass: "text-body-2 font-weight-semibold"
  }, [_vm._v(_vm._s((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : _vm$data10.manufacturer_serial_number))])])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Type: "), _c('span', {
    staticClass: "text-body-2"
  }, [_c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s(_vm.formatAssetType((_vm$data11 = _vm.data) === null || _vm$data11 === void 0 ? void 0 : _vm$data11.type)))])], 1)])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Status : "), _c('span', {
    staticClass: "text-body-2"
  }, [((_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : _vm$data12.status) == 'Active' ? _c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s((_vm$data13 = _vm.data) === null || _vm$data13 === void 0 ? void 0 : _vm$data13.status))]) : ((_vm$data14 = _vm.data) === null || _vm$data14 === void 0 ? void 0 : _vm$data14.status) == 'Inactive' ? _c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s((_vm$data15 = _vm.data) === null || _vm$data15 === void 0 ? void 0 : _vm$data15.status))]) : _c('b-badge', {
    staticClass: "badge-glow",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(_vm._s((_vm$data16 = _vm.data) === null || _vm$data16 === void 0 ? void 0 : _vm$data16.status))])], 1)])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Purchase Cost: "), _c('FormatCurrency', {
    attrs: {
      "currency": (_vm$data17 = _vm.data) === null || _vm$data17 === void 0 ? void 0 : _vm$data17.currency,
      "amount": (_vm$data18 = _vm.data) === null || _vm$data18 === void 0 ? void 0 : _vm$data18.cost
    }
  })], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Repair Cost: "), _c('FormatCurrency', {
    attrs: {
      "currency": (_vm$data19 = _vm.data) === null || _vm$data19 === void 0 ? void 0 : _vm$data19.currency,
      "amount": (_vm$data20 = _vm.data) === null || _vm$data20 === void 0 ? void 0 : _vm$data20.repair_cost_sum
    }
  })], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Total Cost: "), _c('FormatCurrency', {
    attrs: {
      "currency": (_vm$data21 = _vm.data) === null || _vm$data21 === void 0 ? void 0 : _vm$data21.currency,
      "amount": (_vm$data22 = _vm.data) === null || _vm$data22 === void 0 ? void 0 : _vm$data22.total_cost
    }
  })], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Purchase Date: "), _c('span', {
    staticClass: "text-body-2"
  }, [_vm._v(_vm._s(_vm.formatPurchaseDate((_vm$data23 = _vm.data) === null || _vm$data23 === void 0 ? void 0 : _vm$data23.purchase_date)))])])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base"
  }, [_vm._v(" Total Warranty (days): "), (_vm$data24 = _vm.data) !== null && _vm$data24 !== void 0 && _vm$data24.total_days ? _c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(_vm._s((_vm$data25 = _vm.data) === null || _vm$data25 === void 0 ? void 0 : _vm$data25.total_days))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Warranty Left (days): "), (_vm$data26 = _vm.data) !== null && _vm$data26 !== void 0 && _vm$data26.warranty ? _c('b-badge', {
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(_vm._s((_vm$data27 = _vm.data) === null || _vm$data27 === void 0 ? void 0 : _vm$data27.warranty))]) : _c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v("N/A")])], 1)]), _c('b-list-group-item', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v("Description :")])]), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.asset-description-log-modal",
      modifiers: {
        "asset-description-log-modal": true
      }
    }],
    staticClass: "btn-sm",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "AlertCircleIcon"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "mt-1",
    domProps: {
      "innerHTML": _vm._s((_vm$data28 = _vm.data) === null || _vm$data28 === void 0 ? void 0 : _vm$data28.description)
    }
  })], 1), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v("Specifications")]), _vm._l(_vm.assetAttributeInfo, function (info, index) {
    var _info$asset_category_;
    return _c('h6', {
      key: index,
      staticClass: "text-base font-weight-semibold"
    }, [_vm._v(" " + _vm._s(info === null || info === void 0 ? void 0 : (_info$asset_category_ = info.asset_category_attribute) === null || _info$asset_category_ === void 0 ? void 0 : _info$asset_category_.name) + " : "), _c('span', {
      staticClass: "text-body-2"
    }, [_vm._v(_vm._s(info === null || info === void 0 ? void 0 : info.value))])]);
  })], 2)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "asset-description-log-modal",
      "scrollable": "",
      "title": "Description History",
      "hide-footer": "",
      "size": "lg"
    }
  }, [_c('b-card-text', {
    staticClass: "mt-1"
  }, [_c('app-timeline', _vm._l(_vm.assetAcitivityLog, function (log, index) {
    return _c('app-timeline-item', {
      key: index,
      attrs: {
        "variant": "primary"
      }
    }, [_c('small', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatDateTime(log === null || log === void 0 ? void 0 : log.created_at)))]), _c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatLogDescription(log))
      }
    })])]);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card>
    <!-- danger -->
    <template v-if="asset?.status == 'Assigned'">
      <b-alert variant="warning" show>
        <h4 class="alert-heading">Asset Is Assigned!</h4>
        <div class="alert-body">
          <span
          >This asset is already assigned to
            <b>{{ assignedUserInfo?.name }} ({{ assignedUserInfo?.email }})</b
            >.</span
          >
          <br/>
          <span> Release this asset first to assign it to another user. </span>
        </div>
      </b-alert>
    </template>
    <template v-else-if="asset?.status == 'Damaged'">
      <b-alert variant="danger" show>
        <h4 class="alert-heading">Asset Is Damaged!</h4>
        <div class="alert-body">
          <span> You cannot use this asset </span>
        </div>
      </b-alert>
    </template>

    <template v-else-if="asset?.status == 'Available'">
      <b-alert variant="success" show>
        <h4 class="alert-heading">Asset Is Available!</h4>
        <div class="alert-body">
          <span>You can assign this asset to a user </span><br/>
          <template v-if="assignedUserInfo">
            <span
            >This asset was last assigned to
              <b>{{ assignedUserInfo?.name }} ({{ assignedUserInfo?.email }})</b
              >.</span
            >
          </template>
        </div>
      </b-alert>
    </template
    >

    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
            class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <b-form-input
              v-on:keyup="onSearch"
              v-model.lazy="searchTerm"
              placeholder="Search By Assigned User.."
              type="text"
              class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
          />
          <template v-if="asset?.status == 'Available'">
            <template
                v-if="
                $permissionAbility(ASSET_REPAIR_HISTORY_CREATE, permissions)
              "
            >
              <b-button
                  class="flex-shrink-0"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-on:click="showModal"
              >
                Assign
              </b-button>
            </template>
          </template>

          <template v-if="asset?.status == 'Assigned'">
            <template
                v-if="
                $permissionAbility(ASSET_REPAIR_HISTORY_CREATE, permissions)
              "
            >
              <b-button
                  class="flex-shrink-0"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  v-on:click="releaseAsset"
              >
                Release
              </b-button>
            </template>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
          mode="remote"
          styleClass="vgt-table table-custom-style striped"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :line-numbers="false"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :rows="rows"
          compactMode
          :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'assigned_date', type: 'desc' }],
        }"
          :columns="columns"
          :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <template v-if="props.column.field === 'format_assigned_to'">

            <UserCard :user="props?.row?.user_id" />
          </template>
          <template v-if="props.column.field === 'format_assigned_date'">
            <div>
              <span>{{ formatDate(props?.row?.assigned_date) }}</span
              ><br/>
              <span> {{ formatDateTime(props?.row?.assigned_date) }}</span>
            </div>
          </template>
          <!-- assigned_date -->
          <!-- remarks -->

          <div
              v-if="props.column.field === 'format_purpose'"
              class="text-center"
          >
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-on:click="showDescriptionModal(props.row?.purpose)"
                class="btn-sm"
            >
              <feather-icon icon="InfoIcon"/>
            </b-button>
          </div>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                  />
                </template>
                <template
                    v-if="
                    $permissionAbility(ASSET_REPAIR_HISTORY_EDIT, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50"/>
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template
                    v-if="
                    $permissionAbility(ASSET_REPAIR_HISTORY_DELETE, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50"/>
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                  v-model="pageLength"
                  :options="['10', '20', '50']"
                  class="mx-1"
                  @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18"/>
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18"/>
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
        id="description-modal"
        centered
        title="Purpose"
        hide-footer
        @hidden="hiddenDescriptionModal"
        no-close-on-backdrop
    >
      <b-card-text>
        {{ modalDescription }}
      </b-card-text>
    </b-modal>
    <b-modal
        id="modal-asset-assign-user-form"
        centered
        :title="
        modelType == 'editModel' ? 'Edit History' : 'Assign Asset To User'
      "
        hide-footer
        @hidden="hiddenModal"
        no-close-on-backdrop
    >
      <validation-observer ref="assetAssignUserValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group
              label="Assigned Employee "
              label-for="user_id"
              class="required-label"
          >
            <ValidationProvider
                name="assigned employee"
                v-slot="{ errors }"
                vid="user_id"
                rules="required"
            >
              <v-select
                  id="user-id"
                  placeholder="Assign an Employee"
                  v-model="assignedUserId"
                  :options="userIdOption"
                  :reduce="(user) => user.id"
                  label="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select assign date -->
          <b-form-group
              label="Assign Date Time"
              label-for="assigned-date"
              class="required-label"
          >
            <ValidationProvider
                name="assign date"
                v-slot="{ errors }"
                vid="assigned_date"
                rules="required"
            >
              <flat-pickr
                  id="assigned_date"
                  v-model="assignedDate"
                  class="form-control"
                  :state="errors.length > 0 ? false : null"
                  locale="en-US"
                  place
                  :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i:S ',
                }"
                  placeholder="Set Assign Date Time"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- select return date -->
          <template v-if="modelType == 'editModel'">
            <b-form-group
                label="Return Date"
                label-for="returned-date"
                class="required-label"
            >
              <ValidationProvider
                  name="return date"
                  v-slot="{ errors }"
                  vid="returned_date"
                  rules="required"
              >
                <b-form-datepicker
                    id="returned-date"
                    locale="en-US"
                    v-model="returnedDate"
                    reset-button
                    today-button
                    close-button
                    placeholder="Select Return Date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </template>
          <template v-else>
            <b-form-group label="Return Date" label-for="returned-date">
              <ValidationProvider
                  name="return date"
                  v-slot="{ errors }"
                  vid="returned_date"
              >
                <b-form-datepicker
                    id="returned-date"
                    locale="en-US"
                    v-model="returnedDate"
                    reset-button
                    today-button
                    close-button
                    placeholder="Select Return Date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-form-group>
          </template>

          <!-- assign purpose -->
          <b-form-group
              label="Purpose "
              label-for="purpose"
              class="required-label"
          >
            <ValidationProvider
                name="purpose"
                v-slot="{ errors }"
                vid="purpose"
                rules="required"
            >
              <b-form-textarea v-model="purpose"></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isAssetAssignUserFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small/>
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BImg,
  BLink,
  BFormDatepicker,
  BFormTextarea,
  VBTooltip,
  BAlert,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import { DownloadCloudIcon } from 'vue-feather-icons'

import {
  ASSET_REPAIR_HISTORY_ACCESS,
  ASSET_REPAIR_HISTORY_CREATE,
  ASSET_REPAIR_HISTORY_EDIT,
  ASSET_REPAIR_HISTORY_DELETE,
} from '@/helpers/permissionsConstant'
import flatPickr from 'vue-flatpickr-component'
import { format } from 'date-fns'
import UserCard from "@/layouts/components/UserCard.vue";

export default {
  name: 'AssetAssignUser',
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BModal,
    BSpinner,
    UserCard,
    BImg,
    BLink,
    DownloadCloudIcon,
    BFormTextarea,
    BAlert,
    BRow,
    BCol,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      ASSET_REPAIR_HISTORY_ACCESS,
      ASSET_REPAIR_HISTORY_CREATE,
      ASSET_REPAIR_HISTORY_EDIT,
      ASSET_REPAIR_HISTORY_DELETE,
      isLoading: false,
      userIdOption: [],
      modalDescription: '',
      assetId: '',
      assignedUserId: '',
      assignedDate: '',
      returnedDate: '',
      purpose: '',
      assetAssignHistoryId: '',
      modelType: '',
      asset: '',
      assignedUserInfo: '',
      pageLength: 10,
      columns: [
        {
          label: 'Assigned To',
          field: 'format_assigned_to',
          sortable: false,
        },
        {
          label: 'Assigned By',
          field: 'assigned_by.name',
          sortable: false,
        },

        {
          label: 'Assigned Date',
          field: 'format_assigned_date',
          sortable: false,
        },
        {
          label: 'Return Date',
          field: 'returned_date',
          formatFn: this.formatDate,
          sortable: false,
        },
        {
          label: 'Purpose',
          field: 'format_purpose',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{
          field: 'assigned_date',
          type: 'desc'
        }],
        page: 1,
        perPage: 10,
      },
      isAssetAssignUserFormSubmitLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'userModule/getPermissions',
    }),
    statusVariant() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return (status) => statusColor[status]
    },
  },

  async created() {
    try {
      this.getAssetInfo()
      // this.loadItems();
      const activeUsers = await this.$api.get(`/api/departments/employee-all`)

      this.userIdOption = (activeUsers?.data?.data || []).map((item) => {
        let name = item.name
        return {
          name,
          id: item.id,
        }
      })
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          variant: 'danger',
          text: error?.response?.data?.message,
        },
      })
    }
  },

  methods: {
    showDescriptionModal(remarks) {
      if (remarks) {
        this.modalDescription = remarks
      } else {
        this.modalDescription = 'N/A'
      }

      this.$bvModal.show('description-modal')
    },
    hiddenDescriptionModal() {
      this.$bvModal.hide('description-modal')
    },
    formatDate(value) {
      if (value) {
        return this.$moment(value)
            .format('MMMM DD, YYYY')
      }
      return 'Not Set'
    },
    formatDateTime(value) {
      if (value) {
        return this.$moment(value)
            .format('h:mm A')
      }
      return 'Not Set'
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      })
      this.loadItems()
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
        this.delayTimer = null
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems()
      }, 1000)
    },

    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async getAssetInfo() {
      const asset = await this.$api.get(`/api/assets/${this.$route.params.id}`)

      this.asset = asset?.data?.data
    },
    showModal() {
      const currentDate = new Date()
      const formattedDate = format(currentDate, 'yyyy-MM-dd HH:mm:ss')
      this.assignedDate = formattedDate
      this.$bvModal.show('modal-asset-assign-user-form')
    },

    hiddenModal() {
      this.$bvModal.hide('modal-asset-assign-user-form')
      this.resetModal()
    },

    resetModal() {
      this.modelType = ''
      this.assetId = ''
      this.assignedUserId = ''
      this.assignedDate = ''
      this.returnedDate = ''
      this.purpose = ''
      this.assetAssignHistoryId = ''
      this.assignedUserInfo = ''
    },

    onShow(value) {
      this.modelType = 'editModel'
      this.assetId = value.asset_id
      this.assignedUserId = value.user_id.id
      this.assignedDate = value.assigned_date
      this.returnedDate = value.returned_date
      this.purpose = value.purpose
      this.assetAssignHistoryId = value.id
      this.showModal()
    },

    async releaseAsset() {
      this.assetId = this.$route.params.id

      this.$swal({
        title: 'Warning!',
        text: 'Are You Sure You Want To Release This Asset?',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                await this.$api.post('/api/asset-released', {
                  asset_id: this.assetId,
                })

                this.loadItems()
                this.getAssetInfo()
                this.$emit('loadAssetInfo')
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Asset Released Successfully',
                  },
                })
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error?.response?.data?.message,
                  },
                })
              }
            }
          })
    },

    async onDelete(id) {
      this.$swal({
        title: 'Warning!',
        text: 'Are You Sure You Want To Delete This?',
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: 'Ok',
        showLoaderOnConfirm: true,
      })
          .then(async (result) => {
            if (result.isConfirmed) {
              try {
                await this.$api.delete(`/api/asset-assigned-histories/${id}`)

                this.loadItems()

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'History Successfully Deleted',
                  },
                })
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'BellIcon',
                    variant: 'danger',
                    text: error?.response?.data?.message,
                  },
                })
              }
            }
          })
    },
    async getAssetUserAssignHistory(params) {
      this.assetId = this.$route.params.id
      return await this.$api.get(`api/asset-assigned-histories/all`, {
        params: {
          asset_id: this.assetId,
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      })
    },
    async loadItems() {
      try {
        const assetInfo = await this.getAssetUserAssignHistory({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        })

        const data = assetInfo?.data?.data
        const meta = assetInfo?.data?.meta
        this.rows = data
        this.totalRecords = meta?.pagination?.total

        if (
            this.serverParams.sort[0].type == 'desc' &&
            this.serverParams.sort[0].field == 'assigned_date'
        ) {
          this.assignedUserInfo = data[0]?.user_id
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'BellIcon',
            variant: 'danger',
            text: error?.response?.data?.message,
          },
        })
      }
    },
    validationForm: async function () {
      this.$refs.assetAssignUserValidation.validate()
          .then(async (success) => {
            if (success) {
              try {
                if (this.modelType == 'editModel') {
                  this.isAssetAssignUserFormSubmitLoading = true

                  await this.$api.put(
                      `/api/asset-assigned-histories/${this.assetAssignHistoryId}`,
                      {
                        user_id: this.assignedUserId,
                        asset_id: this.$route.params.id,
                        assigned_date: this.assignedDate,
                        returned_date: this.returnedDate,
                        purpose: this.purpose,
                      }
                  )
                  this.isAssetAssignUserFormSubmitLoading = false
                  this.loadItems()

                  this.hiddenModal()

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      icon: 'BellIcon',
                      variant: 'success',
                      text: 'History Successfully Updated',
                    },
                  })
                } else {
                  this.isAssetAssignUserFormSubmitLoading = true

                  await this.$api.post('/api/asset-assigned-histories', {
                    asset_id: this.$route.params.id,
                    user_id: this.assignedUserId,
                    assigned_date: this.assignedDate,
                    returned_date: this.returnedDate,
                    purpose: this.purpose,
                  })
                  this.isAssetAssignUserFormSubmitLoading = false
                  this.hiddenModal()

                  this.loadItems()

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      icon: 'BellIcon',
                      variant: 'success',
                      text: 'Asset Assigned To User',
                    },
                  })
                  this.getAssetInfo()
                  this.$emit('loadAssetInfo')
                }
              } catch (error) {
                this.isAssetAssignUserFormSubmitLoading = false
                if (error?.response?.data?.message) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Error',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: error?.response?.data?.message,
                    },
                  })
                }

                if (error?.response?.data?.errors) {
                  this.$refs.assetAssignUserValidation.setErrors(
                      error?.response?.data?.errors
                  )
                }
              }
            }
          })
    },
  },
}
</script>

<style lang="scss">
.asset-assign-table {
  // font-size: 13px !important;
  // font-weight: 600 !important;
  min-height: 137px !important;
  white-space: nowrap !important;
  font-size: 13px !important;

  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.remarks-para p {
  margin-bottom: 0rem !important;
  font-size: 13px !important;
}

.required-label label::after {
  content: " *";
  color: red;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>


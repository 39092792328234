<template>
  <b-card>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex align-items-center justify-content-end">
        <div
          class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
        >
          <template
            v-if="$permissionAbility(ASSET_REPAIR_HISTORY_CREATE, permissions)"
          >
            <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
            >
              Add
            </b-button>
          </template>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        mode="remote"
        styleClass="vgt-table table-custom-style striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :line-numbers="false"
        :totalRows="totalRecords"
        :rows="rows"
        :columns="columns"
        :sort-options="{
          enabled: false,
          multipleColumns: true,
          initialSortBy: [{ field: 'repair_date', type: 'desc' }],
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- remarks -->
          <span v-if="props.column.field === 'remarks_data'">
            <div class="remarks-para" v-html="props.row?.remarks"></div>
          </span>
          <span v-if="props.column.field === 'format_cost'">
            <div>{{ props.row?.cost }} {{ data?.currency }}</div>
          </span>
          <!-- data -->
          <!-- Image  -->
          <span v-if="props.column.field === 'attach_file_item'">
            <b-link :href="props?.row?.attach_file" target="_blank">
              <FileMinusIcon /> File
            </b-link>
          </span>

          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <template
                  v-if="
                    $permissionAbility(ASSET_REPAIR_HISTORY_EDIT, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onShow(props.row)">
                    <feather-icon icon="Edit2Icon" class="mr-50" />
                    <span>Edit</span>
                  </b-dropdown-item>
                </template>

                <template
                  v-if="
                    $permissionAbility(ASSET_REPAIR_HISTORY_DELETE, permissions)
                  "
                >
                  <b-dropdown-item v-on:click="onDelete(props.row.id)">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </template>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '25', '50', '100', '500']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <b-modal
      id="modalAssetRepairHistoryForm"
      centered
      :title="
        modelType == 'editModel' ? 'Edit Repair History' : 'Add Repair History'
      "
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="assetRepairHistoryValidation">
        <b-form v-on:submit.prevent="validationForm">
          <b-row class="p-1">
            <b-col md="12">
              <b-form-group label="Purchase Date " label-for="purchaseDate">
                <ValidationProvider
                  name="purchase date"
                  v-slot="{ errors }"
                  vid="purchase_date"
                >
                  <b-form-datepicker
                    id="purchaseDate"
                    v-model="purchase_date"
                    :state="errors.length > 0 ? false : null"
                    locale="en-US"
                    reset-button
                    @context="onContextRepairDate"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Repair Date "
                label-for="repairDate"
                class="required-label"
              >
                <ValidationProvider
                  name="repair date"
                  v-slot="{ errors }"
                  vid="repair_date"
                  rules="required"
                >
                  <b-form-datepicker
                    id="repairDate"
                    v-model="repair_date"
                    :state="errors.length > 0 ? false : null"
                    locale="en-US"
                    reset-button
                    @context="onContextRepairDate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col md="8">
              <b-form-group
                label="Cost "
                label-for="cost"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="cost"
                  vid="cost"
                  rules="required"
                >
                  <b-form-input
                    id="cost"
                    type="number"
                    v-model="cost"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Cost"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Currency "
                label-for="cost"
                class="required-label"
              >
                <validation-provider #default="{ errors }">
                  <b-form-input
                    type="text"
                    v-model="currency"
                    :state="errors.length > 0 ? false : null"
                    disabled
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Attach File "
                label-for="attachFile"
                class="required-label"
              >
                <validation-provider
                  #default="{ errors }"
                  name="attached file"
                  :rules="file_url ? '' : 'required|size:2048'"
                  vid="attach_file"
                >
                  <b-form-file
                    id="attachFile"
                    v-model="attach_file"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Remarks "
                label-for="remarks"
                class="required-label"
              >
                <validation-provider
                  name="remarks"
                  vid="remarks"
                  #default="{ errors }"
                  rules="required"
                >
                  <quill-editor
                    v-model="remarks"
                    :options="editorOption"
                    :style="{
                      height: '13rem !important',
                      paddingBottom: '5rem !important',
                    }"
                  >
                    <div id="toolbar" slot="toolbar">
                      <!-- Add a bold button -->
                      <span class="ql-formats">
                        <button class="ql-bold">Bold</button>
                        <button class="ql-italic">Italic</button>
                        <button class="ql-underline">Underline</button>
                        <button class="ql-strike">Strike</button>
                        <button class="ql-blockquote"></button>
                        <button class="ql-list" value="ordered"></button>
                        <button class="ql-list" value="bullet"></button>
                        <button class="ql-script" value="sub"></button>
                        <button class="ql-script" value="super"></button>
                        <button class="ql-indent" value="-1"></button>
                        <button class="ql-indent" value="+1"></button>
                        <button class="ql-direction" value="rtl"></button>
                        <button class="ql-align" value=""></button>
                        <button class="ql-align" value="center"></button>
                        <button class="ql-align" value="right"></button>
                        <button class="ql-align" value="justify"></button>
                        <select class="ql-color"></select>
                        <select class="ql-background"></select>
                        <select class="ql-size">
                          <option value="small" />

                          <option selected />
                          <option value="large" />
                          <option value="huge" />
                        </select>

                        <select class="ql-font"></select>
                        <select class="ql-header">
                          <option value="1">Heading 1</option>
                          <option value="2">Heading 2</option>
                          <option value="3">Heading 3</option>
                          <option value="4">Heading 4</option>
                          <option value="5">Heading 5</option>
                          <option value="6">Heading 6</option>
                          <option selected>Normal</option>
                        </select>

                        <button class="ql-link"></button>
                      </span>
                    </div>
                  </quill-editor>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- loading button -->
          <template v-if="isAssetRepairHistoryFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BImg,
  BLink,
  BFormDatepicker,
  BFormFile,
  BRow,
  BCol,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { FileMinusIcon } from "vue-feather-icons";

import {
  ASSET_REPAIR_HISTORY_ACCESS,
  ASSET_REPAIR_HISTORY_CREATE,
  ASSET_REPAIR_HISTORY_EDIT,
  ASSET_REPAIR_HISTORY_DELETE,
} from "@/helpers/permissionsConstant";
import { File } from "@babel/core";

export default {
  name: "AssetRepairInfo",
  components: {
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BImg,
    BLink,
    FileMinusIcon,
    BFormDatepicker,
    BImg,
    BLink,
    BFormFile,
    BRow,
    BCol,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  props: {
    assetRepairHistory: Array,
    asset: Object,
    data: Object,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },

      ASSET_REPAIR_HISTORY_ACCESS,
      ASSET_REPAIR_HISTORY_CREATE,
      ASSET_REPAIR_HISTORY_EDIT,
      ASSET_REPAIR_HISTORY_DELETE,
      modelType: "",
      assetId: "",
      repairHistoryId: "",
      repair_date: "",
      purchase_date: "",
      cost: "",
      currency: "",
      attach_file: null,
      file_url: "",
      remarks: "",
      pageLength: 10,
      columns: [
        {
          label: "Repair Date",
          field: "repair_date",
          formatFn: this.formatFnTableLastRepairDate,
          sortable: false,
        },

        {
          label: "Cost",
          field: "format_cost",
          sortable: false,
        },

        {
          label: "File",
          field: "attach_file_item",
          sortable: false,
        },
        {
          label: "Remarks",
          field: "remarks_data",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "repair_date", type: "desc" }],
        page: 1,
        perPage: 10,
      },
      isAssetRepairHistoryFormSubmitLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    // try {
    //   this.loadItems();
    // } catch (error) {
    //   this.$toast({
    //     component: ToastificationContent,
    //     props: {
    //       title: "Error",
    //       icon: "BellIcon",
    //       variant: "danger",
    //       text: error?.response?.data?.message,
    //     },
    //   });
    // }
  },

  methods: {
    formatFnTableLastRepairDate(value) {
      if (value) {
        return this.$moment(value).format("MMM D, YYYY");
      }
      return "Not Set";
    },
    onContextRepairDate(ctx) {
      this.purchaseDate = ctx.selectedYMD;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    showModal() {
      this.currency = this.data?.currency;
      this.purchase_date = this.data?.purchase_date;
      this.$bvModal.show("modalAssetRepairHistoryForm");
    },

    hiddenModal() {
      this.$bvModal.hide("modalAssetRepairHistoryForm");
      this.resetModal();
    },

    resetModal() {
      this.assetId = "";
      this.modelType = "";
      this.repairHistoryId = "";
      this.repair_date = "";
      this.cost = "";
      this.attach_file = null;
      this.file_url = "";
      this.remarks = "";
    },

    onShow(value) {
      this.modelType = "editModel";
      this.repairHistoryId = value?.id;
      this.repair_date = value?.repair_date;
      this.cost = value?.cost;
      this.file_url = value?.attach_file;
      this.remarks = value?.remarks;
      this.currency = this.data?.currency;
      this.showModal();
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are you sure you want to delete this record?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/asset-repair-histories/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Item deleted successfully.",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    async getAssetRepairHistoryItems(params) {
      this.assetId = this.$route.params.id;
      return await this.$api.get("api/asset-repair-histories/all", {
        params: {
          asset_id: this.assetId,
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    async loadItems() {
      try {
        const assetRepairHistories = await this.getAssetRepairHistoryItems({
          show: this.serverParams.perPage,
          page: this.serverParams.page,
          sort: this.serverParams.sort,
          q: this.searchTerm,
        });

        const data = assetRepairHistories?.data?.data;
        const meta = assetRepairHistories?.data?.meta;
        this.totalRecords = meta?.pagination?.total;

        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    validationForm: async function () {
      this.$refs.assetRepairHistoryValidation
        .validate()
        .then(async (success) => {
          if (success) {
            const assetId = this.$route.params.id;
            try {
              if (this.modelType == "editModel") {
                this.isAssetRepairHistoryFormSubmitLoading = true;

                const formData = new FormData();
                formData.append("_method", "PUT");
                formData.append("asset_id", assetId);

                formData.append("purchase_date", this.purchase_date);
                if (this.attach_file)
                  formData.append("attach_file", this.attach_file);
                if (this.repair_date)
                  formData.append("repair_date", this.repair_date);
                if (this.cost) formData.append("cost", this.cost);
                if (this.remarks) formData.append("remarks", this.remarks);

                await this.$api.post(
                  `/api/asset-repair-histories/${this.repairHistoryId}`,
                  formData,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                );

                this.isAssetRepairHistoryFormSubmitLoading = false;
                this.loadItems();
                this.hiddenModal();

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Updated successfully",
                  },
                });
              } else {
                this.isAssetRepairHistoryFormSubmitLoading = true;

                const formData = new FormData();
                formData.append("asset_id", assetId);
                formData.append("purchase_date", this.purchase_date);
                if (this.attach_file)
                  formData.append("attach_file", this.attach_file);
                if (this.repair_date)
                  formData.append("repair_date", this.repair_date);
                if (this.cost) formData.append("cost", this.cost);
                if (this.remarks) formData.append("remarks", this.remarks);

                await this.$api.post("/api/asset-repair-histories", formData, {
                  headers: { "Content-Type": "multipart/form-data" },
                });

                this.isAssetRepairHistoryFormSubmitLoading = false;
                this.hiddenModal();
                this.loadItems();
                this.$emit("loadAssetInfo");
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Added successfully",
                  },
                });
              }
            } catch (error) {
              this.isAssetRepairHistoryFormSubmitLoading = false;
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.assetRepairHistoryValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.asset-repair-table {
  min-height: 137px !important;
  white-space: nowrap !important;
  font-size: 13px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.remarks-para p {
  margin-bottom: 0rem !important;
  font-size: 13px !important;
}

.required-label label::after {
  content: " *";
  color: red;
}

@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/apps/calendar.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

var render = function () {
  var _vm$asset, _vm$assignedToAssetIn, _vm$assignedToAssetIn2, _vm$asset2, _vm$assignedToAssetIn3, _vm$assignedToAssetIn4, _vm$asset3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [((_vm$asset = _vm.asset) === null || _vm$asset === void 0 ? void 0 : _vm$asset.status) == 'Assigned' ? [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Asset Is Already Assigned!")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("This asset is already assigned to "), _c('b', [_vm._v(" Company Serial ID : " + _vm._s((_vm$assignedToAssetIn = _vm.assignedToAssetInfo) === null || _vm$assignedToAssetIn === void 0 ? void 0 : _vm$assignedToAssetIn.company_serial_number) + ", Name: " + _vm._s((_vm$assignedToAssetIn2 = _vm.assignedToAssetInfo) === null || _vm$assignedToAssetIn2 === void 0 ? void 0 : _vm$assignedToAssetIn2.title))]), _vm._v(".")]), _c('br'), _c('span', [_vm._v(" Relase this asset first to assign it under another asset ")])])])] : ((_vm$asset2 = _vm.asset) === null || _vm$asset2 === void 0 ? void 0 : _vm$asset2.status) === 'Available' ? [_c('b-alert', {
    attrs: {
      "variant": "success",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Asset Is Available!")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("You can assign it under another asset ")]), _c('br'), _vm.assignedToAssetInfo ? [_c('span', [_vm._v("This asset was last assigned to "), _c('b', [_vm._v(" Company Serial ID : " + _vm._s((_vm$assignedToAssetIn3 = _vm.assignedToAssetInfo) === null || _vm$assignedToAssetIn3 === void 0 ? void 0 : _vm$assignedToAssetIn3.company_serial_number) + ", Name: " + _vm._s((_vm$assignedToAssetIn4 = _vm.assignedToAssetInfo) === null || _vm$assignedToAssetIn4 === void 0 ? void 0 : _vm$assignedToAssetIn4.title))]), _vm._v(".")])] : _vm._e()], 2)])] : _vm._e(), _c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [((_vm$asset3 = _vm.asset) === null || _vm$asset3 === void 0 ? void 0 : _vm$asset3.status) == 'Assigned' ? [_vm.$permissionAbility(_vm.ASSET_REPAIR_HISTORY_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.releaseAsset
    }
  }, [_vm._v(" Release ")])] : _vm._e()] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "rows": _vm.rows,
      "columns": _vm.columns,
      "styleClass": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "totalRows": _vm.totalRecords,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'assigned_date',
          type: 'desc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row2, _props$row2$assigned_, _props$row3, _props$row3$assigned_, _props$row4, _props$row5;
        return [props.column.field === 'format_assigned_to' ? [_c('div', [_c('div', [_c('b-link', {
          on: {
            "click": function click($event) {
              var _props$row, _props$row$assigned_t;
              return _vm.onShowAssetDetails(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$assigned_t = _props$row.assigned_to) === null || _props$row$assigned_t === void 0 ? void 0 : _props$row$assigned_t.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$assigned_ = _props$row2.assigned_to) === null || _props$row2$assigned_ === void 0 ? void 0 : _props$row2$assigned_.title) + " ")]), _c('br'), _c('span', [_vm._v(" ID: " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : (_props$row3$assigned_ = _props$row3.assigned_to) === null || _props$row3$assigned_ === void 0 ? void 0 : _props$row3$assigned_.company_serial_number) + " ")])], 1)])] : _vm._e(), props.column.field === 'format_purpose' ? _c('div', {
          staticClass: "text-center"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.purpose,
            expression: "props?.row?.purpose",
            modifiers: {
              "hover": true,
              "top": true
            }
          }],
          staticClass: "flex-shrink-0 btn-sm text-center",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.purpose.slice(0, 5)) + " ...")])], 1) : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.ASSET_REPAIR_HISTORY_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <AssetInfo
          :data="asset"
          :assetAttributeInfo="assetAttributeInfo"
          :assetAcitivityLog="assetAcitivityLog"
        />
      </div>
      <div class="col-md-8">
        <b-card-code>
          <b-tabs pills>
            <b-tab active title="Repair History">
              <AssetRepairInfo
                :data="asset"
                :assetRepairHistory="assetRepairHistory"
                v-on:loadAssetInfo="updateAssetInfo($event)"
              />
            </b-tab>

            <template>
              <b-tab
                title="Assign History"
                :disabled="asset?.type != 'additional' ? true : false"
              >
                <AdditionalAssetHistory
                  :additionalAssetAssignHistory="assetAssignUserHistory"
                  v-on:loadAssetInfo="updateAssetInfo($event)"
                />
              </b-tab>
            </template>
            <template>
              <b-tab
                title="Assign To User "
                :disabled="asset?.type == 'additional' ? true : false"
              ><AssetAssignUser
                  :assetAssignUserHistory="assetAssignUserHistory"
                  v-on:loadAssetInfo="updateAssetInfo($event)"
                />
              </b-tab>
              <b-tab
                title="Add Additional Asset"
                :disabled="asset?.type == 'additional'"
              >
                <AdditionalAssetAssign
                  :additionalAssetAssignHistory="assetAssignUserHistory"
                  v-on:loadAssetInfo="updateAssetInfo($event)"
                />
              </b-tab>
            </template>
          </b-tabs>
        </b-card-code>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { BTabs, BTab, BCardText, BCard, BRow } from "bootstrap-vue";
import AssetInfo from "./AssetInfo.vue";
import AssetRepairInfo from "./AssetRepairInfo.vue";
import AssetAssignUser from "./AssetAssignUser.vue";
import AdditionalAssetAssign from "./AdditionalAssetAssign.vue";
import AdditionalAssetHistory from "./AdditionalAssetHistory.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "AssetDetailsView",
  components: {
    AssetInfo,
    AssetRepairInfo,
    AssetAssignUser,
    AdditionalAssetAssign,
    AdditionalAssetHistory,
    BCardCode,
    BCardText,
    BTabs,
    BTab,
    BCard,
    BRow,
  },
  data() {
    return {
      asset: {},
      assetRepairHistory: [],
      assetAssignUserHistory: [],
      assetAcitivityLog: [],
      assetAttributeInfo: [],
    };
  },

  async created() {
    this.loadItems();
  },

  methods: {
    updateAssetInfo: function () {
      this.loadItems();
    },

    async getAssetInfo() {
      const assetId = this.$route.params.id;
      return await this.$api.get(
        `api/assets/${assetId}?include=assetRepairHistories,assetAssignedHistories`
      );
    },

    async getAssetAttributeInfo() {
      const assetId = this.$route.params.id;
      return await this.$api.get(
        `api/asset-category/attributes/value/${assetId}`
      );
    },

    // api for extra info from the table

    async getAssetAcitivityLog() {
      const assetId = this.$route.params.id;
      return await this.$api.get(`api/assets/${assetId}/activity-log`);
    },
    async loadItems() {
      try {
        const [assetInfo, assetAttributeInfo, activityLog] = await Promise.all([
          this.getAssetInfo(),
          this.getAssetAttributeInfo(),
          this.getAssetAcitivityLog(),
        ]);

        this.asset = assetInfo?.data?.data;
        this.assetAttributeInfo =
          assetAttributeInfo?.data?.asset_attribute_values;
        this.assetAcitivityLog = activityLog?.data?.data;

        this.assetRepairHistory = this.asset?.assetRepairHistories?.data;
        this.assetAssignUserHistory = this.asset?.assetAssignedHistories?.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>


<style lang="scss">
.disabled-true {
  pointer-events: none !important;
  color: #676d7d !important;
}

.disabled-false {
  pointer-events: all !important;
  color: #d0d2d6 !important;
}
</style>

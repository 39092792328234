<template>
  <div>
    <b-card title="Asset Information">
      <b-img
        center
        :src="data?.asset_image"
        alt="assetImage"
        class="custom-asset-image mb-2"
      ></b-img>

      <b-card-text class="text-center">
        <h3>{{ data?.title }}</h3>
        <b-row class="mt-2">
          <b-col cols="6">
            <h6 class="text-base font-weight-semibold">
              User Assign Count:
              <b-badge v-if="data?.total_assigned" variant="success">{{ data?.total_assigned }}</b-badge> <b-badge v-else variant="danger">N/A</b-badge>
            </h6>
          </b-col>
          <b-col cols="4">
            <h6 class="text-base font-weight-semibold">
              Repair Count:
              <b-badge v-if="data?.total_repaired" variant="success">{{ data?.total_repaired }}</b-badge> <b-badge v-else variant="danger">N/A</b-badge>
            </h6>
          </b-col>
        </b-row>
      </b-card-text>
      <hr />
      <b-card-text>
        <p class="text-uppercase custom-text-disabled">Details</p>
        <b-list-group flush>
          <b-list-group-item
            ><h6 class="text-base">
              Title: <span class="text-body-2 font-weight-semibold">{{ data?.title }}</span>
            </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base">
              Serial ID:
            <b-badge v-if="data?.company_serial_number" variant="info">{{ data?.company_serial_number }}</b-badge> <b-badge v-else variant="danger">N/A</b-badge>
          </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base ">
              Manufacturer ID:
              <span class="text-body-2 font-weight-semibold">{{
                data?.manufacturer_serial_number
              }}</span>
            </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base font-weight-semibold">
              Type:
              <span class="text-body-2">
                <b-badge variant="light-primary">{{
                  formatAssetType(data?.type)
                }}</b-badge></span
              >
            </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base font-weight-semibold">
              Status :
              <span class="text-body-2">
                <b-badge
                  variant="success"
                  class="badge-glow"
                  v-if="data?.status == 'Active'"
                  >{{ data?.status }}</b-badge
                >
                <b-badge
                  variant="danger"
                  class="badge-glow"
                  v-else-if="data?.status == 'Inactive'"
                  >{{ data?.status }}</b-badge
                >
                <b-badge variant="primary" class="badge-glow" v-else>{{
                  data?.status
                }}</b-badge></span
              >
            </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base font-weight-semibold">
              Purchase Cost:
              <FormatCurrency :currency="data?.currency" :amount="data?.cost" />
            </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base font-weight-semibold">
              Repair Cost:
            <FormatCurrency :currency="data?.currency" :amount="data?.repair_cost_sum" />
            </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base font-weight-semibold">
              Total Cost:
            <FormatCurrency :currency="data?.currency" :amount="data?.total_cost" />
            </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base font-weight-semibold">
              Purchase Date:
              <span class="text-body-2">{{
                formatPurchaseDate(data?.purchase_date)
              }}</span>
            </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base">
              Total Warranty (days):
              <b-badge v-if="data?.total_days" variant="primary">{{ data?.total_days }}</b-badge> <b-badge v-else variant="danger">N/A</b-badge>
          </h6></b-list-group-item
          >
          <b-list-group-item
            ><h6 class="text-base font-weight-semibold">
              Warranty Left (days):
            <b-badge v-if="data?.warranty" variant="primary">{{ data?.warranty }}</b-badge> <b-badge v-else variant="danger">N/A</b-badge>

          </h6></b-list-group-item
          >

          <b-list-group-item>
            <b-row>
              <b-col cols="10">
                <h6 class="text-base font-weight-semibold">Description :</h6>
              </b-col>
              <b-col cols="2">
                <b-button
                  class="btn-sm"
                  variant="outline-primary"
                  v-b-modal.asset-description-log-modal
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="AlertCircleIcon" /></b-button
              ></b-col>
            </b-row>

            <div v-html="data?.description" class="mt-1"></div>
          </b-list-group-item>

          <!-- Extra data -->

          <b-list-group-item>
            <h6 class="text-base font-weight-semibold">Specifications</h6>
            <template>
              <h6
                class="text-base font-weight-semibold"
                v-for="(info, index) in assetAttributeInfo"
                :key="index"
              >
                {{ info?.asset_category_attribute?.name }} :
                <span class="text-body-2">{{ info?.value }}</span>
              </h6>
            </template>
          </b-list-group-item>
        </b-list-group>
      </b-card-text>
    </b-card>

    <!-- modal Scrolling Content inside Modal-->

    <b-modal
      id="asset-description-log-modal"
      scrollable
      title="Description History"
      hide-footer
      size="lg"
    >
      <b-card-text class="mt-1">
        <app-timeline>
          <app-timeline-item
            v-for="(log, index) in assetAcitivityLog"
            :key="index"
            variant="primary"
          ><small class="text-muted">{{
              formatDateTime(log?.created_at)
            }}</small>
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0"
            >
              <p v-html="formatLogDescription(log)"></p>
            </div>
          </app-timeline-item>
        </app-timeline>
      </b-card-text>
    </b-modal>

    <!-- for edit modal -->
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { assetTypeConstant } from "@/helpers/constant/assetTypeConstant";
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BRow,
  BImg,
  BListGroup,
  BListGroupItem,
  BBadge,
  BCol,
  BButton,
  BModal,
} from "bootstrap-vue";
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "AssetInfo",
  components: {
    FormatCurrency,
    BCardCode,
    BCardText,
    BTabs,
    BTab,
    BCard,
    BRow,
    BImg,
    BListGroup,
    BBadge,
    BListGroupItem,
    BCol,
    BButton,
    BModal,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      text: "",
      assetTypeConstant,
    };
  },

  props: {
    data: Object,
    assetAcitivityLog: {},
    assetAttributeInfo: {},
  },
  async created() {
    const res = await this.getAssetAttributeInfo();
  },
  methods: {
    formatAssetType(value) {
      const foundItem = this.assetTypeConstant.find(
        (item) => item.value === value
      );

      return foundItem?.name ? foundItem?.name : "";
    },
    async getAssetAttributeInfo() {
      const assetId = this.$route.params.id;
      return await this.$api.get(
        `api/asset-category/attributes/value/${assetId}`
      );
    },
    formatDateTime(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YY, h:mm: a");
      }
    },
    formatLogDescription(value) {
      let description = "";

      if (value?.event == "updated" || value?.event == "created")
        description = value?.properties?.attributes?.description;

      return description;
    },
    formatPurchaseDate(value) {
      if (value) {
        return this.$moment(value).format("MMMM D, YYYY");
      }
      return "Not Set";
    },
  },
};
</script>

<style lang="scss">
.custom-asset-image {
  height: 125px !important;
  width: 135px !important;
}
.custom-text-disabled {
  color: #b7b4b4 !important;
  font-weight: 700 !important;
}
.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.font-weight-semibold {
  font-weight: 600 !important;
}
.text-body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.0178571429em !important;
  text-transform: none !important;
}
blockquote {
  border-left: 4px solid #ccc !important;
  padding-left: 16px !important;
}
</style>

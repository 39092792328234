var render = function () {
  var _vm$asset, _vm$asset2, _vm$asset3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('AssetInfo', {
    attrs: {
      "data": _vm.asset,
      "assetAttributeInfo": _vm.assetAttributeInfo,
      "assetAcitivityLog": _vm.assetAcitivityLog
    }
  })], 1), _c('div', {
    staticClass: "col-md-8"
  }, [_c('b-card-code', [_c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "active": "",
      "title": "Repair History"
    }
  }, [_c('AssetRepairInfo', {
    attrs: {
      "data": _vm.asset,
      "assetRepairHistory": _vm.assetRepairHistory
    },
    on: {
      "loadAssetInfo": function loadAssetInfo($event) {
        return _vm.updateAssetInfo($event);
      }
    }
  })], 1), [_c('b-tab', {
    attrs: {
      "title": "Assign History",
      "disabled": ((_vm$asset = _vm.asset) === null || _vm$asset === void 0 ? void 0 : _vm$asset.type) != 'additional' ? true : false
    }
  }, [_c('AdditionalAssetHistory', {
    attrs: {
      "additionalAssetAssignHistory": _vm.assetAssignUserHistory
    },
    on: {
      "loadAssetInfo": function loadAssetInfo($event) {
        return _vm.updateAssetInfo($event);
      }
    }
  })], 1)], [_c('b-tab', {
    attrs: {
      "title": "Assign To User ",
      "disabled": ((_vm$asset2 = _vm.asset) === null || _vm$asset2 === void 0 ? void 0 : _vm$asset2.type) == 'additional' ? true : false
    }
  }, [_c('AssetAssignUser', {
    attrs: {
      "assetAssignUserHistory": _vm.assetAssignUserHistory
    },
    on: {
      "loadAssetInfo": function loadAssetInfo($event) {
        return _vm.updateAssetInfo($event);
      }
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Add Additional Asset",
      "disabled": ((_vm$asset3 = _vm.asset) === null || _vm$asset3 === void 0 ? void 0 : _vm$asset3.type) == 'additional'
    }
  }, [_c('AdditionalAssetAssign', {
    attrs: {
      "additionalAssetAssignHistory": _vm.assetAssignUserHistory
    },
    on: {
      "loadAssetInfo": function loadAssetInfo($event) {
        return _vm.updateAssetInfo($event);
      }
    }
  })], 1)]], 2)], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {
  var _vm$assignedByAssetIn, _vm$assignedByAssetIn2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_vm.assignedByAssetInfo ? [_c('b-alert', {
    attrs: {
      "variant": "success",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" This asset was last Assigned by ID: " + _vm._s((_vm$assignedByAssetIn = _vm.assignedByAssetInfo) === null || _vm$assignedByAssetIn === void 0 ? void 0 : _vm$assignedByAssetIn.company_serial_number) + " - Name: " + _vm._s((_vm$assignedByAssetIn2 = _vm.assignedByAssetInfo) === null || _vm$assignedByAssetIn2 === void 0 ? void 0 : _vm$assignedByAssetIn2.title) + ". ")])])] : !_vm.assignedByAssetInfo ? [_c('b-alert', {
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v(" No Additional Asset Assigned Under This Asset! ")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_vm._v("You can assign additional asset under this asset ")])])])] : _vm._e(), _c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [[_vm.$permissionAbility(_vm.ASSET_REPAIR_HISTORY_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Add ")])] : _vm._e()]], 2)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "styleClass": "vgt-table table-custom-style striped",
      "line-numbers": false,
      "totalRows": _vm.totalRecords,
      "rows": _vm.rows,
      "columns": _vm.columns,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'assigned_date',
          type: 'desc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$row2, _props$row2$assigned_, _props$row3, _props$row3$assigned_;
        return [props.column.field === 'format_assigned_by' ? [_c('div', [_c('b-link', {
          on: {
            "click": function click($event) {
              var _props$row, _props$row$assigned_b;
              return _vm.onShowAssetDetails(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$assigned_b = _props$row.assigned_by) === null || _props$row$assigned_b === void 0 ? void 0 : _props$row$assigned_b.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$assigned_ = _props$row2.assigned_by) === null || _props$row2$assigned_ === void 0 ? void 0 : _props$row2$assigned_.title) + " ")]), _c('br'), _c('span', [_vm._v(" ID: " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : (_props$row3$assigned_ = _props$row3.assigned_by) === null || _props$row3$assigned_ === void 0 ? void 0 : _props$row3$assigned_.company_serial_number) + " ")])], 1)] : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.ASSET_REPAIR_HISTORY_EDIT, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1)] : _vm._e(), _vm.$permissionAbility(_vm.ASSET_REPAIR_HISTORY_DELETE, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Delete")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-asset-assign-asset-form",
      "centered": "",
      "title": _vm.modelType == 'editModel' ? 'Edit History' : 'Add Additional Asset',
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "assetAssignAdditionalAssetValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('div', [_vm.modelType != 'editModel' ? [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Assigned Asset ",
      "label-for": "assigned-to"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assigned asset",
      "vid": "assigned_by",
      "rule": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          attrs: {
            "id": "user-id",
            "placeholder": "Assign To An Asset",
            "options": _vm.assetIdOption,
            "reduce": function reduce(user) {
              return user.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.additionalAssetId,
            callback: function callback($$v) {
              _vm.additionalAssetId = $$v;
            },
            expression: "additionalAssetId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3237842064)
  })], 1)] : _vm._e()], 2), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Assign Date ",
      "label-for": "assigned-date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "assign date",
      "vid": "assigned_date",
      "rule": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-datepicker', {
          attrs: {
            "id": "assigned-date",
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": "",
            "placeholder": "Set Assign Date"
          },
          model: {
            value: _vm.assignedDate,
            callback: function callback($$v) {
              _vm.assignedDate = $$v;
            },
            expression: "assignedDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Release Date",
      "label-for": "release-date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "release date",
      "vid": "release_date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-datepicker', {
          attrs: {
            "id": "release-date",
            "locale": "en-US",
            "reset-button": "",
            "today-button": "",
            "close-button": "",
            "placeholder": "Select Return Date"
          },
          model: {
            value: _vm.releaseDate,
            callback: function callback($$v) {
              _vm.releaseDate = $$v;
            },
            expression: "releaseDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isAdditionalAssetAssignFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }